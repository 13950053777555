<script lang="ts">
	import { Spinner } from "sveltestrap";
	import { onMount } from "svelte";
	import { getPost } from "../lib/backend";
	import type { PostData } from "../lib/types";
	import PostThumbnail from "./thumbnails/PostThumbnail.svelte";
	export let postIDs: string[] = [];
	let posts: Record<string, Promise<PostData>> = {};

	onMount(async () => {
		for (let id of postIDs) {
			posts[id] = getPost(id);
		}
	});
</script>

{#if postIDs.length > 0}
	{#each postIDs as postID}
		{#await posts[postID]}
			<PostThumbnail postData={null} />
		{:then postData}
			<PostThumbnail {postData} />
		{/await}
		<br>
	{/each}
{:else}
	There are no posts in this category
{/if}