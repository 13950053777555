<script lang="ts">
	import { ProgressBar } from "carbon-components-svelte";
	import { onMount } from "svelte";
	import { request } from "../lib/backend";
	export let data = null;
	async function fetchData() {
		data = await request(`/post.json`);
	}

	onMount(() => fetchData());
</script>

<div class="container">
	{#if data}
		<h1>{data.title}</h1>
		<p>{data.fulltext}</p>
	{:else}
		<ProgressBar label="Loading..."/>
	{/if}
</div>
