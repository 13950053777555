<script lang="ts">
	import Navbar from "./components/Navbar.svelte";
	import "./main.css";
	import router from "page";
	import Post from "./routes/Post.svelte";
	import Notfound from "./routes/Notfound.svelte";
	import LatestPosts from "./routes/LatestPosts.svelte";
	import type { RouterParams } from "./lib/types";
	import { Grid, Row } from "carbon-components-svelte";
	import Login from "./routes/Login.svelte";
	import { onMount } from "svelte";
	import auth from "./auth/service";
	import { isAuthenticated, user } from "./auth/store";

	let page;
	let params: RouterParams;
	let auth0Client;

	const setParams =
		(_default: RouterParams = {}) =>
		(ctx, next) => {
			params = {
				..._default,
				...ctx.params,
			};
			next();
		};

	const routeTo = (p) => () => (page = p);
	router("/", setParams({ topic: "news" }), routeTo(LatestPosts));

	router("/login", setParams(), routeTo(Login));

	router("/topic/:topic", setParams(), routeTo(LatestPosts));

	router("/post/:id", setParams(), routeTo(Post));

	router("*", routeTo(Notfound));
	router.start();

	onMount(async () => {
		auth0Client = await auth.createClient();
		isAuthenticated.set(await auth0Client.isAuthenticated());
		user.set(await auth0Client.getUser());
	});

	function login() {
		console.log("testin");
		auth.loginWithPopup(auth0Client, {});
	}
</script>

<Navbar login={() => login()} />
<br />
<Grid>
	<svelte:component this={page} {params} />
</Grid>
