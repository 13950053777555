<script lang="ts">
	import { Tile, Button, ClickableTile } from "carbon-components-svelte";
	import UserThumbnail from "./UserThumbnail.svelte";
	import { Chat, ThumbsUp } from "carbon-icons-svelte";
	import type { PostData } from "../../lib/types";
	import PlaceholderCardThumbnail from "./PlaceholderCardThumbnail.svelte";
	export let postData: PostData = null;
</script>

{#if postData != null}
	<ClickableTile
		href="/post/{postData.id}"
		textContent={postData.fulltext.slice(0, 50)}
		title={postData.title}
	>
		<img
			class="user-thumb"
			src={"https://via.placeholder.com/150x150"}
			width="40"
			height="40"
			alt="avatar"
		/>
		<h3>
			{postData.title}
		</h3>
		<small><i>by {postData.id}, {postData.created}</i></small>
		<p class="card-text">
			<span>
				{postData.fulltext.slice(0, 50)}
			</span>
			<br />
			<span>
				{postData.fulltext.slice(50, 75)}...
			</span>
		</p>
		<hr />
		<div class="flex flex-v">
			<span
				><Chat /><i
					><sup class="badge">{postData.replies.length}</sup></i
				></span
			>
			<span
				><ThumbsUp /><i
					><sup class="badge">{postData.likes.length}</sup></i
				></span
			>
		</div>
	</ClickableTile>
{:else}
	<PlaceholderCardThumbnail />
{/if}

<style>
	.badge {
		background-color: grey;
		color: white;
		border-radius: 5px;
		padding-right: 3px;
		padding-left: 3px;
		font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	}

	.user-thumb {
		border-radius: 100%;
		border: 3px solid white;
		box-shadow: inset;
	}
</style>
