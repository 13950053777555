<script lang="ts">
	import { ProgressBar } from "carbon-components-svelte";
	import { onMount } from "svelte";
	import { listPosts } from "../lib/backend";
	import type { RouterParams } from "../lib/types";
	import PostList from "./../components/PostList.svelte";
	export let params: RouterParams;
	let postIDs: string[] = [];
	onMount(async () => {
		postIDs = await listPosts(params.topic);
	});
</script>

{#if postIDs.length > 0}
	<PostList {postIDs} />
{:else}
	<ProgressBar label="Loading..." />
{/if}
