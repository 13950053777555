<script lang="ts">
	import {
		OverflowMenu,
		OverflowMenuItem,
		Button,
	} from "carbon-components-svelte";
	import auth from "../auth/service";
	import { isAuthenticated, user } from "../auth/store";

	export let login = () => {};
</script>

<div class="navbar-main flex-h">
	<div>
		<OverflowMenu>
			<OverflowMenuItem href="/" text="Home" />
			<OverflowMenuItem href="/topic/discussion" text="Forum" />
		</OverflowMenu>
	</div>
	<div>
		{#if $isAuthenticated}
			Logged in as {$user.name}
		{:else}
			<Button kind="ghost" size="field"  on:click={() => login()}>
				Login
			</Button>
		{/if}
	</div>
</div>

<style>
	.navbar-main {
		background-color: #fcfcfc !important;
		display: flex;
		flex-direction: row;
		align-items: center;
	}
</style>
