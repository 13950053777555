const endpoint = "https://api.exploringthekeep.com";
export async function request(route) {
    const resp = await fetch(endpoint + route, {
        headers: {
            "Access-Control-Allow-Headers": "*",
        }
    });
    return resp.json();
}
export function getPost(id) {
    return request(`/post.json`);
}
export function listPosts(cat) {
    return request(`/list_posts.json`);
}
